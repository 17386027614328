const NavItems = [
  {
    name: "brain",
    location: "/brain",
  },
  {
    name: "newsletter",
    location: "/newsletter",
  },
  {
    name: "articles",
    location: "/articles",
  },
];

export default NavItems;
